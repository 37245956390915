import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";

// material-ui icons
import { Delete, Edit, Assignment } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";

// core components
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import UserTable from "components/ReactTable/ReactTable";
import { deleteUsers, getUsers } from "../../actions/userAction";
import { roles } from "../../variables/roles";
import UpdateModal from "../User/UpdateModal";
import ViewStaffDetails from "../User/ViewStaffDetails";
import { Link } from "react-router-dom";

const useStyles = makeStyles({ ...tableStyles, ...alertStyles });

export default function Users() {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.users);
  const totalPages = useSelector((state) => state.users.totalPagesActive);
  const [numberOfRows, setNumberOfRows] = React.useState(20);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [sortBy, setSortBy] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [classicModal, setClassicModal] = React.useState(false);
  const [staffModal, setStaffModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();

  useEffect(() => {
    dispatch(getUsers(0, 20, "companies")).then(() => {
      setLoading(false);
    });
  }, []);

  const onFetchData = async (page, size, sortBy, filters) => {
    setLoading(true);
    dispatch(getUsers(page, size, "companies", sortBy, filters)).then(() => {
      setLoading(false);
    });
  };

  const successDelete = async (userID) => {
    dispatch(
      deleteUsers({
        userID: userID,
        deletetype: "archive",
        callback: () => {
          setAlert(
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="User has been archived successfully!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnCssClass={classes.button + " " + classes.success}
            >
              User has been archived.
            </SweetAlert>
          );
          let deletedUserIndex = usersData.data.findIndex(
            (u) => u._id === userID
          );
          usersData.data[deletedUserIndex].ArchivedAt = Date.now();
          usersData.data[deletedUserIndex].active = false;
          usersData.deletedUsers.push(usersData.data[deletedUserIndex]);
        },
        callbackfail: () => {
          setAlert(
            <SweetAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Something went wrong!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnCssClass={classes.button + " " + classes.success}
            >
              Please try again later.
            </SweetAlert>
          );
        },
      })
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const warningWithConfirmMessage = (userID) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(userID)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, archive it!"
        cancelBtnText="Cancel"
        showCancel
      >
        Are you sure you want to archive this user ?
      </SweetAlert>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Company Name",
        accessor: "employer.companyName",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const roundButtons = ({ userID, role, active, user }) => {
    let btnArr;
    if (active) {
      btnArr = [
        { type: "view", color: "primary", icon: VisibilityIcon },
        { type: "edit", color: "success", icon: Edit },
        { type: "archive", color: "warning", icon: Delete },
      ];
    } else {
      btnArr = [{ type: "edit", color: "success", icon: Edit }];
    }
    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          onClick={() => {
            if (prop.type === "edit") {
              setClassicModal({
                isVisible: true,
                userID,
              });
            } else if (prop.type === "view") {
              setStaffModal({
                isVisible: true,
                user,
              });
            } else if (prop.type === "archive") {
              warningWithConfirmMessage(userID, "archive");
            }
          }}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };

  const renderTable = React.useMemo(() => {
    const users = usersData.data;
    if (!users || !users.length) {
      return <>No Data Found!</>;
    }
    const filteredUsers = users
      .filter((user) => user.role === roles.employer)
      .map((user, id) => ({
        ...user,
        id: id + 1 + pageIndex * numberOfRows,
        companyName: user?.employer?.companyName,
        name: `${user.firstName} ${user.lastName}`,
        actions: roundButtons({
          userID: user._id,
          role: user.role,
          active: user.active,
          user: user,
        }),
      }));

    return (
      <UserTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        numberOfRows={numberOfRows}
        setNumberOfRows={setNumberOfRows}
        initSortBy={sortBy}
        setSortBy={setSortBy}
        initFilters={filters}
        setFilters={setFilters}
        columns={columns}
        data={filteredUsers}
        onFetchData={onFetchData}
        totalPages={totalPages}
      />
    );
  }, [pageIndex, numberOfRows, usersData]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <div className="d-flex justify-content-between align-items-center ">
              <h4 className={classes.cardIconTitle}>Company Users</h4>
              <Link
                to="/admin/companies/archived-users"
                className="u-text-underline"
              >
                <h5>List of Archived Company Users</h5>
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>{renderTable}</>
            )}
          </CardBody>
        </Card>
        {staffModal.isVisible && (
          <ViewStaffDetails
            approvingUser={usersData.data}
            classicModal={staffModal}
            setClassicModal={setStaffModal}
          />
        )}
        {classicModal.isVisible && (
          <UpdateModal
            users={usersData.data}
            classicModal={classicModal}
            setClassicModal={setClassicModal}
            setLoading={setLoading}
          />
        )}
      </GridItem>
    </GridContainer>
  );
}
