import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// core components
import CircularProgress from "@material-ui/core/CircularProgress";
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import EventTable from "components/ReactTable/ReactTable";
import { getEvents } from "../../actions/eventAction";
import { convertToDateFromMS } from "../../variables/time";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import moment from "moment";

const useStyles = makeStyles(styles);

const EventStatus = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [numberOfRows, setNumberOfRows] = React.useState(20);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [sortBy, setSortBy] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const [classicModal, setClassicModal] = React.useState({
    isVisible: false,
    type: "create",
  });
  const [eventLoading, setEventLoading] = React.useState(false);
  const eventsData = useSelector((state) => state.events);
  const totalPages = useSelector((state) => state.events.totalPages);

  const onFetchData = async (page, size, sortBy, filters) => {
    dispatch(getEvents(page, size, sortBy, filters)).then(() => {});
  };

  const classes = useStyles();

  const columns = React.useMemo(
    () => [
      {
        Header: "Event ID",
        accessor: "eventID",
      },
      {
        Header: "Event Name",
        accessor: "eventName",
      },
      {
        Header: "Start",
        accessor: "eventStart",
        sortType: (a, b, columnId) => {
          var a1 = moment(a?.values?.eventStart, "DD-MM-YYYY").format("x");
          var b1 = moment(b?.values?.eventStart, "DD-MM-YYYY").format("x");
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: "End",
        accessor: "eventEnd",
        sortType: (a, b, columnId) => {
          var a1 = moment(a?.values?.eventEnd, "DD-MM-YYYY").format("x");
          var b1 = moment(b?.values?.eventEnd, "DD-MM-YYYY").format("x");
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: "Status",
        accessor: "eventStatus",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const roundButtons = ({ data }) => {
    let btnArr;
    btnArr = [{ type: "info", color: "info", icon: ArrowForwardIcon }];

    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          onClick={async () => {
            if (prop.type === "info") {
              history.push(`/admin/eventstatus/${data._id}`);
            }
          }}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };
  const renderTable = React.useMemo(() => {
    const events = eventsData.data;
    if (!events || !events.length) {
      return <>No Data Found!</>;
    }
    const tabledata = [];
    events.forEach((event, id) => {
      let jobStartDates = [];
      let jobEndDates = [];
      let isjobbooked = [];
      let jobPositionCount = 0;
      let contractSignedCount = 0;
      event.jobs.map((job) => {
        job.jobPositions.map((jobPosition, key) => {
          jobPositionCount++;
          if (
            jobPosition?.booked?.employee &&
            jobPosition?.contractSubmited &&
            jobPosition?.signedContract
          ) {
            contractSignedCount++;
          }
          jobStartDates = [...jobStartDates, jobPosition.jobStart];
          jobEndDates = [...jobEndDates, jobPosition.jobEnd];
          if (jobPosition?.booked && jobPosition?.booked?.employee) {
            isjobbooked = [...isjobbooked, true];
          }
        });
      });
      const eventEndDate = jobEndDates.reduce(
        (previousValue, currentValue) =>
          previousValue < currentValue ? currentValue : previousValue,
        jobEndDates[0]
      );
      const eventStartdate = jobStartDates.reduce(
        (previousValue, currentValue) =>
          previousValue > currentValue ? currentValue : previousValue,
        jobStartDates[0]
      );
      if (isjobbooked.length > 0) {
        tabledata.push({
          ...event,
          id: id + 1,
          eventStart: eventStartdate
            ? moment(convertToDateFromMS(eventStartdate)).format(
                "dddd, DD.MM.YY"
              )
            : "",
          eventEnd: eventEndDate
            ? moment(convertToDateFromMS(eventEndDate)).format("dddd, DD.MM.YY")
            : "",
          eventStatus: (
            <span
              className={`${
                jobPositionCount === contractSignedCount
                  ? "eventStatusGreen"
                  : "eventStatusRed"
              }`}
            ></span>
          ),
          actions: roundButtons({
            data: event,
          }),
        });
      }

      return {
        ...event,
      };
    });
    return (
      <EventTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        numberOfRows={numberOfRows}
        setNumberOfRows={setNumberOfRows}
        initSortBy={sortBy}
        setSortBy={setSortBy}
        initFilters={filters}
        setFilters={setFilters}
        columns={columns}
        data={tabledata}
        onFetchData={onFetchData}
        totalPages={totalPages}
      />
    );
  }, [pageIndex, numberOfRows, eventsData]);

  useEffect(() => {
    // if (eventsData && eventsData.data && eventsData.data.length) return;
    dispatch(getEvents()).then(() => {});
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Event Status</h4>
          </CardHeader>
          <CardBody>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={(e) =>
                // setClassicModal({
                //   ...classicModal,
                //   type: "create",
                //   data: null,
                //   isVisible: true,
                // })
                history.push("/admin/events/create-event")
              }
            >
              Add Event
            </Button> */}
            {eventsData.loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {eventLoading && (
                  <div className="loader absolute-loader">
                    <CircularProgress />
                  </div>
                )}
                {renderTable}
              </>
            )}
          </CardBody>
          {/* <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
              <DialogTitle>Event Name</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Eveniet necessitatibus minus velit nisi error dignissimos
                  quibusdam natus, eius, quae repellendus asperiores, fugit
                  corrupti similique dicta! Dolor cumque ducimus inventore ab.
                </DialogContentText>
              </DialogContent>
            </Dialog> */}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EventStatus;
